import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import CryptoJS from 'crypto-js';

//const key = CryptoJS.enc.Base64.parse("AbpJFT-1sQro5Ibc_REHSDHDWpjomjbtON1K4rE71RI="); // Your AES key
//const iv = CryptoJS.lib.WordArray.random(16); // Initialization vector (optional, but recommended)

// Function to encrypt the phone number using AES
// const encryptMobileNumber = (clientCode, key) => {
//   const encrypted = CryptoJS.AES.encrypt(clientCode, key, { iv: iv }).toString();
//   return encrypted;
// };

// Function to decrypt the encrypted phone number using AES
// const decryptMobileNumber = (encryptedData, key) => {
//   const decrypted = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });
//   return decrypted.toString(CryptoJS.enc.Utf8);
// };

class StringEncryptor {
  constructor() {
    // Key should be a 32-byte key, encoded in Base64, same as in the Python version.
    this.key = "AbpJFT-1sQro5Ibc_REHSDHDWpjomjbtON1K4rE71RI=";
  }

  // Encrypt method
  encrypt(plainText) {
    const keyBytes = CryptoJS.enc.Base64.parse(this.key);  // Parse the Base64 key
    const encrypted = CryptoJS.AES.encrypt(plainText.toString(), keyBytes, {
      mode: CryptoJS.mode.ECB,  // Use ECB mode to be compatible with Python's Fernet
      padding: CryptoJS.pad.Pkcs7  // Ensure padding is PKCS7
    }).toString();
    return encrypted;
  }

  // Decrypt method
  decrypt(encryptedText) {
    const keyBytes = CryptoJS.enc.Base64.parse(this.key);  // Parse the Base64 key
    const decrypted = CryptoJS.AES.decrypt(encryptedText, keyBytes, {
      mode: CryptoJS.mode.ECB,  // Use ECB mode to be compatible with Python's Fernet
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);  // Convert decrypted bytes to UTF8 string
  }
}

// const base64Encode = (str) => {
//   return btoa(str);
// };

// // Function to Base64 decode a string
// const base64Decode = (str) => {
//   return atob(str);
// };

// // Function to encrypt the phone number using Base64 encoding
// const encryptMobileNumber = (phoneNumber, key) => {
//   const phoneNumberStr = phoneNumber.toString();  // Convert the phone number to a string
//   const encodedNumber = base64Encode(phoneNumberStr); // Base64 encode the stringified phone number
//   return `${encodedNumber}.${base64Encode(key)}`;  // Concatenate with the encoded key
// };

// // Function to decrypt the phone number using Base64 decoding
// const decryptMobileNumber = (encryptedData, key) => {
//   const [encodedNumber, encodedKey] = encryptedData.split('.');  // Split the encrypted data
//   if (base64Decode(encodedKey) !== key) {
//     throw new Error('Invalid key for decryption');  // Throw an error if the key doesn't match
//   }
//   return base64Decode(encodedNumber);  // Base64 decode the encrypted number
// };

const PlsLogin = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState(null);
  const [encryptedMobileNumber, setEncryptedMobileNumber] = useState('');
  const [decryptedMobileNumber, setDecryptedMobileNumber] = useState('');
  const [clientCode, setClientCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState();

  const stringEncryptor = new StringEncryptor();
  //const key = "AbpJFT-1sQro5Ibc_REHSDHDWpjomjbtON1K4rE71RI=";

  useEffect(() => {
    var storedData = JSON.parse(localStorage.getItem('verifiedData'));
    if (storedData && storedData.data) {
      setClientCode(storedData.data);
      setPhoneNumber(storedData.Phone_Number);
      console.log("=================================== storedData ====================================");
      console.log(storedData);
    }
    // console.log("here is the datas", storedData.session);
    console.log("stored data - ", storedData);
  }, [phoneNumber]);

  React.useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('verifiedData'));
    if (storedData) {
      setUserData(storedData.data);
    }
  }, []);

  // useEffect(() => {
  //   if (phoneNumber) {
  //     // Encrypt the phone number
  //     const encrypted = encryptMobileNumber(phoneNumber, key);
  //     setEncryptedMobileNumber(encrypted);
  //     console.log("Encrypted phone number: ", encrypted);

  //     const decrypted = decryptMobileNumber(encrypted, key);
  //     setDecryptedMobileNumber(decrypted);
  //     console.log("Decrypted phone number: ", decrypted);
  //   }
  // }, [phoneNumber]);

  // Fetch phone number from local storage and encrypt it
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('verifiedData'));
    if (storedData && storedData.Phone_Number) {
      setPhoneNumber(storedData.Phone_Number);  // Set phone number in state

      // Encrypt the phone number
      const encrypted = stringEncryptor.encrypt(storedData.Phone_Number);
      setEncryptedMobileNumber(encrypted);
      console.log("Encrypted phone number: ", encrypted);

      // Decrypt the encrypted phone number to verify it's working
      const decrypted = stringEncryptor.decrypt(encrypted);
      setDecryptedMobileNumber(decrypted);
      console.log("Decrypted phone number: ", decrypted);
    }
  }, []);

  // useEffect(() => {
  //   if (phoneNumber) {
  //     const encrypted = encryptMobileNumber(phoneNumber, key);  // Encrypt the phone number
  //     setEncryptedMobileNumber(encrypted);  // Store the encrypted number in state
  //     console.log("Encrypted phone number: ", encrypted);

  //     const decrypted = decryptMobileNumber(encrypted, key);  // Decrypt to verify it's working
  //     setDecryptedMobileNumber(decrypted);  // Store the decrypted number in state
  //     console.log("Decrypted phone number: ", decrypted);
  //   }
  // }, [phoneNumber]);

  const handelNavigation = () => {
    navigate('/sign-in/');
  };

  const handlePocketNavigation = () => {
    console.log(decryptedMobileNumber.toString());
    const pocketUrl = `https://pocket.adityatrading.in/?sso=${encryptedMobileNumber}`;
    console.log("Connecting to Pocket -->> ", pocketUrl);
    window.location.href = pocketUrl;
  };

  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        marginTop: 2,
        marginBottom: 4,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          border: userData ? '1px solid #34a853' : '1px solid #ff3d00',
          width: '85%',
          backgroundColor: userData ? '#d1ffdd' : '#fbe9e7',
          padding: '0.5rem',
          borderRadius: '0.25rem',
          position: 'relative',
        }}
      >
        {userData ? (
          <>
            <p style={{ color: '#34a853', fontSize: '14px', paddingLeft: '10px' }}>
              "Dive into the world of investing and trading with our pocket app. 💰🚀
              Discover new opportunities, and make informed decisions."
            </p>
            <button
              onClick={handlePocketNavigation}
              style={{
                cursor: 'pointer',
                borderRadius: '0.25rem',
                color: '#fff',
                backgroundColor: '#34a853',
                borderColor: '#dc3545',
                display: 'inline-block',
                textAlign: 'center',
                verticalAlign: 'middle',
                fontWeight: 400,
                marginTop: '8px',
                marginLeft: '8px',
                marginRight: '8px',
                border: '1px solid',
                paddingTop: '7px',
                paddingBottom: '7px',
                paddingLeft: '12px',
                paddingRight: '12px',
              }}
            >
              Get To Pocket-Web
            </button>
          </>
        ) : (
          <>
            <p style={{ color: '#e64141', fontSize: '14px', paddingLeft: '10px' }}>
              Please Login with your Mobile Number
            </p>
            <p style={{ color: '#374948', fontSize: '12px', paddingLeft: '10px' }}>
              Login to access our stock recommendation and many more offerings.
            </p>
            <button
              onClick={handelNavigation}
              style={{
                cursor: 'pointer',
                borderRadius: '0.25rem',
                color: '#fff',
                backgroundColor: '#dc3545',
                borderColor: '#dc3545',
                display: 'inline-block',
                textAlign: 'center',
                verticalAlign: 'middle',
                fontWeight: 400,
                marginTop: '8px',
                marginLeft: '8px',
                marginRight: '8px',
                border: '1px solid',
                paddingTop: '7px',
                paddingBottom: '7px',
                paddingLeft: '12px',
                paddingRight: '12px',
              }}
            >
              Login Now
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PlsLogin;

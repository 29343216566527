import React, { useEffect, useRef, useState } from 'react';
import FooterContent from '../Footer/FooterContent';
import './Signin.css';
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  Container,
} from '@mui/material';
import LandingPage from '../LandingPage';
import { useTheme } from '@mui/material/styles';
import { useCheckOTPMutation, useGenerateOtpMutation } from '../redux/otp';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';


const VerifyOtp = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // State for OTP inputs
  const [otp, setOtp] = useState({
    first: '',
    second: '',
    third: '',
    fourth: '',
    fifth: '',
    sixth: '',
  });

  const [phoneNumber, setPhoneNumber] = useState('');
  const [checkOTP, { isLoading, isSuccess, data, error }] = useCheckOTPMutation();

  // Refs for input fields
  const inputRefs = {
    first: useRef(),
    second: useRef(),
    third: useRef(),
    fourth: useRef(),
    fifth: useRef(),
    sixth: useRef(),
  };

  useEffect(() => {
    // Retrieve phone number from localStorage when component mounts
    const phone = localStorage.getItem('sessionPhoneKEY');
    if (phone) {
      setPhoneNumber(phone);
      console.log("We are saving this phone number ---- ", phone);
    }
  }, []);

  // Handle OTP input change and focus shift
  const handleOtpChange = (e) => {
    const { name, value } = e.target;

    // Update the OTP state
    setOtp({ ...otp, [name]: value });

    // Automatically move focus to the next input field when input is filled
    if (value.length === 1 && inputRefs[name]) {
      const nextInput = getNextInput(name);
      if (nextInput && inputRefs[nextInput]) {
        inputRefs[nextInput].current.focus();
      }
    }
  };

  // Function to get the next input field name
  const getNextInput = (currentName) => {
    switch (currentName) {
      case 'first':
        return 'second';
      case 'second':
        return 'third';
      case 'third':
        return 'fourth';
      case 'fourth':
        return 'fifth';
      case 'fifth':
        return 'sixth';
      default:
        return null;
    }
  };

  // Handle OTP verification
  const verifyOtp = async () => {
    const completeOtp = Object.values(otp).join('');
    try {
      const result = await checkOTP({
        phone_number: phoneNumber,
        otp: completeOtp,
      }).unwrap();
      if (result.Status === 'Success') {
        alert('Phone number verified successfully');
        localStorage.setItem('verifiedData', JSON.stringify(result));
        console.log("result here ----", result);
        sessionStorage.setItem('clientCode', JSON.stringify(result.data));
        sessionStorage.setItem('phonenumber', JSON.stringify(result.Phone_Number));
        localStorage.removeItem('sessionPhoneKEY');
        navigate('/');
      } else {
        alert('OTP verification failed');
      }
    } catch (err) {
      console.error('Failed to verify OTP:', err);
      alert('An error occurred while verifying OTP');
    }
  };

  return (
    <div>
      <LandingPage />
      <div style={{ position: 'relative', top: '102px', height: 'auto' }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} lg={6} sm={8} md={6}>
            <img
              src="https://adityatrading.in/static/images/banners/trade-desktop.png"
              alt="Banner Image"
              style={{ marginTop: '20px', width: '100%' }}
            />
          </Grid>
          <Grid
            sx={{ minHeight: '300px', lineHeight: '93px' }}
            item
            xs={12}
            lg={6}
            sm={8}
            md={6}
          >
            <h5>Verify Your Identity</h5>
            <p>Enter 6-Digit Code We Sent to Your Phone Number</p>
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                maxWidth: '200px',
              }}
            >
              {['first', 'second', 'third', 'fourth', 'fifth', 'sixth'].map(
                (name, index) => (
                  <input
                    key={index}
                    ref={inputRefs[name]}
                    type="text"
                    name={name}
                    value={otp[name]}
                    onChange={handleOtpChange}
                    maxLength="1"
                    style={{
                      borderRadius: '5px',
                      width: '30px',
                      textAlign: 'center',
                      marginRight: '5px',
                      fontSize: '18px',
                      border: '1px solid #a7a4a4',
                      lineHeight: '30px',
                      color: '#777D74',
                    }}
                  />
                )
              )}
            </div>
            <div style={{ width: '100%', marginTop: '20px' }}>
              <Button
                onClick={verifyOtp}
                sx={{
                  color: '#e5f2ff!important',
                  border: '1px solid #e5f2ff!important',
                  paddingTop: '7px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  paddingBottom: '7px',
                  fontSize: '14px',
                  fontWeight: 500,
                  backgroundColor: '#34a853 ',
                  width: '100%',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#34a853',
                  },
                }}
              >
                Verify OTP
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <FooterContent />
    </div>
  );
};

export default VerifyOtp;

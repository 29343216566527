import React from 'react';
import './styles.css';

const phrases = [
  {
    text: "Stay Alert: Fraudsters posing as ATS employees may promise high returns. ATS never guarantees returns or accepts payments via third-party accounts. Protect Your Demat Account: Update your mobile number with your DP to receive instant alerts on important transactions directly from CDSL. KYC Made Simple: Complete KYC once with a SEBI-registered intermediary—no need to repeat it when switching. Easy IPO Payments: No cheques needed! Just authorize payment through your bank account for IPOs, keeping your money secure.",
    url: '/theft-identity/',
  },
];

export default function App() {
  return (
    <div className="marquee-container">
      <div className="marquee">
        {phrases.map((phrase, index) => (
          <span key={index} className="marquee-item">
            {phrase.text}
            <a style={{ marginLeft: '20px' }} href={phrase.url}>
              Learn more
            </a>
          </span>
        ))}
      </div>
    </div>
  );
}

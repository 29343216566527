// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: relative;
  background: #f0f0f0; /* Change as per your design */
  padding: 10px 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

.marquee {
  display: inline-block;
  animation: marquee 90s linear infinite;
}

.marquee-item {
  display: inline-block;
  padding: 0 15px;
  font-size: 16px; /* Adjust font size as needed */
  color: #333; /* Change as per your design */
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.marquee-container:hover .marquee {
  animation-play-state: paused;
}
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB,EAAE,8BAA8B;EACnD,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,sCAAsC;AACxC;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,eAAe,EAAE,+BAA+B;EAChD,WAAW,EAAE,8BAA8B;AAC7C;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".marquee-container {\r\n  overflow: hidden;\r\n  white-space: nowrap;\r\n  box-sizing: border-box;\r\n  position: relative;\r\n  background: #f0f0f0; /* Change as per your design */\r\n  padding: 10px 0;\r\n  margin-bottom: 20px;\r\n  margin-top: 20px;\r\n}\r\n\r\n.marquee {\r\n  display: inline-block;\r\n  animation: marquee 90s linear infinite;\r\n}\r\n\r\n.marquee-item {\r\n  display: inline-block;\r\n  padding: 0 15px;\r\n  font-size: 16px; /* Adjust font size as needed */\r\n  color: #333; /* Change as per your design */\r\n}\r\n\r\n@keyframes marquee {\r\n  from {\r\n    transform: translateX(100%);\r\n  }\r\n  to {\r\n    transform: translateX(-100%);\r\n  }\r\n}\r\n\r\n.marquee-container:hover .marquee {\r\n  animation-play-state: paused;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";
import PublicIcon from "@mui/icons-material/Public";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import CallIcon from "@mui/icons-material/Call";
import "./Footer.css";
import { useNavigate } from "react-router";

const FooterContent = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#f8f9fa",
        position: "relative",
        top: "70px",
      }}
    >
      <footer>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="menu-row">
                <ul
                  style={{ listStyle: "none", margin: "0px", padding: "0px" }}
                >
                  <li>
                    <h4 className="footer-title">About Us</h4>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() =>
                      window.open(
                        "https://adityatrading.in/media/KMP/Key-Managerial-Personnel-ATS.pdf",
                        "_blank"
                      )
                    }
                  >
                    <p>Key Managerial Personnel</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/investment-philosophy/")}
                  >
                    <p>Investment Philosophy</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/bank-account-details/")}
                  >
                    <p>Registered Bank Details</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open("https://adityatrading.greythr.com/login.do");
                    }}
                  >
                    <p>Greytip</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open("https://neuron.adityatrading.in/");
                    }}
                  >
                    <p>Neuron</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/careers/")}
                  >
                    <p>Careers</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/branches/")}
                  >
                    <p>Branches</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/contactus/")}
                  >
                    <p>Contact us</p>
                  </li>
                  <li>
                    <h4 className="footer-title">Investor Charter</h4>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/investor-charter-dp/")}
                  >
                    <p>- DPs</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() =>
                      handleNavigation("/investor-charter-stock-broker/")
                    }
                  >
                    <p>- Stock Broker</p>
                  </li>
                  <li>
                    <h4 className="footer-title">Monthly Disclosure</h4>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/monthly-disclosure-dp/")}
                  >
                    <p>- DPs</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() =>
                      handleNavigation("/monthly-disclosure-stockbroker/")
                    }
                  >
                    <p>- Stock Broker</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="menu-row">
                <ul
                  style={{ listStyle: "none", margin: "0px", padding: "0px" }}
                >
                  <li>
                    <h4 className="footer-title">Research</h4>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/")}
                  >
                    <p>Campaign Login</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/")}
                  >
                    <p>Knowledge Center</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/share-market-news/")}
                  >
                    <p>Daily Morning Reports</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/")}
                  >
                    <p>Company Reports</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/")}
                  >
                    <p>Sector Reports</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/")}
                  >
                    <p>Commodity Reports</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/")}
                  >
                    <p>IPO Reports</p>
                  </li>
                  <li>
                    <h4 className="footer-title">Other Reports</h4>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/posts/")}
                  >
                    <p>Posts</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/mutual-funds/")}
                  >
                    <p>Mutual Funds</p>
                  </li>
                  <li className="hover-para">
                    <p>FAQ’s</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/circulars/")}
                  >
                    <p>Circulars</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/analyst_opinions/")}
                  >
                    <p>Analyst Opinions</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="menu-row">
                <ul
                  style={{ listStyle: "none", margin: "0px", padding: "0px" }}
                >
                  <li>
                    <h4 className="footer-title">Investor Corner</h4>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() =>
                      window.open("http://pg.adityatrading.com/WebClient/")
                    }
                  >
                    <p>Back Office Login</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() =>
                      handleNavigation("/account-opening-process/")
                    }
                  >
                    <p>Account Opening Process</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/investment-grievance/")}
                  >
                    <p>Investors Grievance</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() =>
                      window.open("https://adityatrading.in/static/download/")
                    }
                  >
                    <p>XTS Terminal Download</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/form-center-resources/")}
                  >
                    <p>Form Center/ Resources</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/kyc-checklist/")}
                  >
                    <p>KYC Checklist</p>
                  </li>
                  <li className="hover-para">
                    <p>Rights and Obligations</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() =>
                      window.open(
                        "https://adityatrading.in/media/riskdisclosure/Risk-Disclosure-Document-ATS.pdf"
                      )
                    }
                  >
                    <p>Risk Disclosure Document</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/ats-policy/")}
                  >
                    <p>ATS Policy</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() =>
                      window.open(
                        "https://adityatrading.in/media/authorized-persons-details/ATS-AP.pdf"
                      )
                    }
                  >
                    <p>Authorized Persons Details</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/how-to-file-complaint/")}
                  >
                    <p>How to file a complaint</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="menu-row">
                <ul
                  style={{ listStyle: "none", margin: "0px", padding: "0px" }}
                >
                  <li>
                    <h4 className="footer-title">Markets</h4>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open("https://nseindia.com/");
                    }}
                  >
                    <p>NSE</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open("http://www.bseindia.com/");
                    }}
                  >
                    <p>BSE</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open("https://www.mcxindia.com/");
                    }}
                  >
                    <p>MCX</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open("http://www.sebi.gov.in/");
                    }}
                  >
                    <p>SEBI</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open("https://scores.sebi.gov.in/");
                    }}
                  >
                    <p>SCORES</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open("https://www.cdslindia.com/");
                    }}
                  >
                    <p>CDSL</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => handleNavigation("/holiday-calendar/")}
                  >
                    <p>Market Holidays</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open(
                        "https://evoting.cdslindia.com/Evoting/EvotingLogin"
                      );
                    }}
                  >
                    <p>CDSL e-Voting</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open("https://smartodr.in/login");
                    }}
                  >
                    <p>ODR Portal</p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open(
                        "https://investorhelpline.nseindia.com/ClientCollateral/welcomeCLUser"
                      );
                    }}
                  >
                    <p>Segregation Report</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="menu-row">
                <ul
                  style={{ listStyle: "none", margin: "0px", padding: "0px" }}
                >
                  <li>
                    <h4 className="footer-title">Head Office</h4>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open("https://goo.gl/maps/7A41Dv4QhSaTWa5u6");
                    }}
                  >
                    <p>
                      ATS Share Brokers Pvt Ltd, 2gethr, 1st Floor, Tower B,
                      Mantri Commercio, Kariyamana Agraharam, Bellandur
                      Bengaluru- 560 103.
                    </p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open("tel:+917667273344");
                    }}
                  >
                    <p>
                      <CallIcon sx={{ width: "15px", height: "15px" }} /> +91
                      766 727 3344
                    </p>
                  </li>
                  <li
                    className="hover-para"
                    onClick={() => {
                      window.open("mailto:support@adityatrading.com");
                    }}
                  >
                    <p>
                      <EmailIcon sx={{ width: "15px", height: "15px" }} />{" "}
                      support@adityatrading.com
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="footer-content-heading">
              <div className="footer-content">
                <p>
                  ATS is India’s Most Preferred & Trusted Financial Services
                  Company with more than 1 Lakh Satisfied Clients who enjoy the
                  service from 800+ Locations, backed by 24x7 Expert Support and
                  offers the Lowest Brokerage in the market. ATS Share Brokers
                  Pvt.Ltd. is an Authorised Member of NSE, BSE, MCX, MCX-SX and
                  NCDEX. We provide one of the best investment and online
                  trading platforms to our clients which runs on all platforms
                  including web, mobile and so on. To know more you’ll have to
                  experience our services. We have made trading easy to our
                  customers in such a way that our customers can trade anywhere,
                  anytime.
                </p>
                <p>
                  ATS: CDSL SEBI Registration Number - IN-DP-CDSL-656-2012 | DP
                  ID - 12074300 | BSE Membership Code - 6481 | BSE SEBI
                  Registration Number - Capital Market: INB011384030 |
                  Derivatives: INF011384030 | NSE Membership Code - 13840 | NSE
                  SEBI Registration Number – Capital Market: INB231384034 |
                  Derivatives: INF231384034 | Currency Market: INE231384034 |
                  MCX-SX Membership Code - 73800 | MCX-SX SEBI Registration
                  Number - Currency Market: INE261384031 | ATS: MCX Membership
                  Code - 10795 | FMC Code: MCX/TCM/CORP/0039 | NCDEX Membership
                  Code - 00278 | FMC Registration Code: NCDEX/TCM/CORP/0322.
                </p>
                <p>
                  The Stock Exchange, Mumbai is not responsible or liable in any
                  way to any individual or group for acts of omission,
                  commission, errors, mistakes, or violations—whether actual or
                  perceived—committed by us, our partners, agents, or
                  associates, in relation to the Rules, Regulations, and
                  Bye-laws of the Stock Exchange, Mumbai, the SEBI Act, or any
                  other prevailing laws. Furthermore, the Stock Exchange, Mumbai
                  holds no responsibility or liability for any information
                  provided on this website or for any services rendered by us,
                  our employees, or servants.
                </p>
                <p>
                  Investors no longer need to issue cheques when subscribing to
                  an IPO. Simply provide your bank account number and sign the
                  application form to authorize your bank to make the payment
                  upon allotment. There’s no need to worry about refunds, as the
                  funds remain in your account until allocation.
                </p>
                <p>
                  KYC (Know Your Customer) is a one-time process when dealing in
                  the securities market. Once completed through a
                  SEBI-registered intermediary such as a broker, DP, or mutual
                  fund, you won’t need to repeat it when working with other
                  intermediaries.
                </p>
                <p>
                  Attention Investors: As of September 1, 2020, stock brokers
                  can only accept securities as margins from clients through a
                  pledge within the depository system. To ensure a smooth
                  process, please update your email and phone number with your
                  stock broker or depository participant, so you can receive
                  OTPs directly from the depository for creating pledges.
                  Additionally, it is important to regularly review your
                  securities, mutual funds, and bonds in the consolidated
                  account statement issued each month by NSDL/CDSL. This notice
                  is issued in the interest of investors.
                </p>
                <p>
                  To prevent unauthorized transactions in your account, please
                  update your mobile number and email ID with your stock broker.
                  This will allow you to receive direct transaction information
                  from the Exchange on your mobile and email at the end of each
                  day. This notice is issued in the interest of investors.
                  <br />
                  KYC (Know Your Customer) is a one-time process when dealing in
                  the securities markets. Once your KYC is completed through a
                  SEBI-registered intermediary such as a broker, depository
                  participant (DP), or mutual fund, you do not need to repeat
                  the process with other intermediaries.
                  <br />
                  Dear Investor, if you are subscribing to an IPO, there is no
                  need to issue a cheque. Simply provide your bank account
                  number and sign the IPO application form to authorize your
                  bank to make the payment in case of allotment. If the
                  allotment is not made, the funds will remain in your bank
                  account.
                  <br />
                  As a business, we do not provide stock tips and have not
                  authorized anyone to trade on behalf of others. If you
                  encounter anyone claiming to represent ATS and offering such
                  services, please report it.{" "}
                </p>
                <p>
                  <u>Registered Office Address:</u> ATS Share Brokers Pvt Ltd,
                  62/6871, Shop No. A11, Jos Annex Building, 4th Floor, Jos
                  Junction, M.G. Road, Cochin, Ernakulam,682016 Tel.
                  <a style={{ color: "#34a853" }} href="callto:+91-7667273344">
                    +91-7667273344
                  </a>
                  , Email id.
                  <a
                    style={{ color: "#34a853" }}
                    href="mailto:support@adityatrading.com"
                  >
                    support@adityatrading.com
                  </a>
                </p>
                <p>
                  <u>Correspondence Address:</u> ATS Share Brokers Pvt Ltd, 1st
                  Floor, Tower B(2gethr), Mantri Commercio, Outer Ring Road,
                  Near Sakra World Hospital, Bellandur, Bangalore, Karnataka,
                  560103. Tel.
                  <a style={{ color: "#34a853" }} href="callto:+91-7667273344">
                    +91-7667273344
                  </a>
                  , Email id.
                  <a
                    style={{ color: "#34a853" }}
                    href="mailto:support@adityatrading.com"
                  >
                    support@adityatrading.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="rowfoot">
            <div>
              <p className="para">
                <PublicIcon /> English (India)
              </p>
            </div>
            <div className="last">
              <ul className="row">
                <li onClick={() => handleNavigation("/desc/")}>Disclaimer</li>
                <li>Sitemap</li>
                <li onClick={() => handleNavigation("/tnc/")}>
                  Terms & Condition
                </li>
                <li onClick={() => handleNavigation("/privacypolicy/")}>
                  Privacy Policy
                </li>
                <li onClick={() => handleNavigation("/theft-identity/")}>
                  Theft Identity
                </li>
              </ul>
            </div>
            <div className="icn">
              <ul className="row">
                <li>
                  <a
                    href="https://www.facebook.com/atssharebrokers"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/atssharebrokers/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC0IkpBwdzc7Q95pV93rr5YQ/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <YouTubeIcon />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/ats-share-brokers/posts/?feedView=all"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon />
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/atssharemarket"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TelegramIcon />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FooterContent;

// https://www.youtube.com/channel/UC0IkpBwdzc7Q95pV93rr5YQ/
// https://www.linkedin.com/company/ats-share-brokers/posts/?feedView=all
// https://t.me/atssharemarket

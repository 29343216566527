import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './Company.css';
import { useTechnicalNewsMutation } from '../redux/technicaltable';
import { useSelector } from 'react-redux';

const Weekly = () => {
  const [daily, setDaily] = useState({});
  const [dailyData, { isLoading }] = useTechnicalNewsMutation();
  const companyName = useSelector((state) => state.token.companyName);

  console.log(daily);
  console.log(companyName);

  const updateData = async () => {
    const fetchData = await dailyData({
      exchange: 'NSE',
      segment: 'CM',
      tickerCode: companyName,
    });

    setDaily(fetchData.data.data || {}); // Ensure daily is an object or empty object
  };

  useEffect(() => {
    updateData();
  }, [companyName, dailyData]);
  const formatNum = (number) => {
    const rounded = Math.floor(number * 100) / 100;
    return rounded.toFixed(2);
  };
  // Convert daily to array and filter
  const classic = Object.keys(daily)
    .filter((key) => key.includes('weeklyclassicPivotPoints'))
    .map((key) => daily[key]);

  const Fibonacci = Object.keys(daily)
    .filter((key) => key.includes('weeklyfibonaciPivotPoints'))
    .map((key) => daily[key]);

  const Camarilla = Object.keys(daily)
    .filter((key) => key.includes('weeklycamarillaPivotPoints'))
    .map((key) => daily[key]);

  return (
    <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
      <p> Technical Rating </p>
      <span
        style={{
          color: '#374948',
          backgroundColor: '#ffd400',
          borderRadius: '100px',
          maxWidth: '120px',
          display: 'block',
          paddingLeft: '7px',
          paddingRight: '7px',
          paddingBottom: '5px',
          paddingTop: '5px',
          textAlign: 'center',
        }}
      >
        Neutral
      </span>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <table className="smalltable">
            <tbody>
              <tr>
                <td>Open</td>
                <td>22 1</td>
              </tr>
              <tr>
                <td>High</td>
                <td>221.7</td>
              </tr>
              <tr>
                <td>Low</td>
                <td>217</td>
              </tr>
              <tr>
                <td>Volume</td>
                <td>222</td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <div style={{ overflowX: 'auto', width: '100%' }}>
          <div>
            <h4 className="hh4">Pivot Levels</h4>
          </div>
          <div>
            <table className="smalltable">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th style={{ paddingLeft: '10px' }} scope="col">
                    R1
                  </th>
                  <th style={{ paddingLeft: '10px' }} scope="col">
                    R2
                  </th>
                  <th style={{ paddingLeft: '10px' }} scope="col">
                    R3
                  </th>
                  <th style={{ paddingLeft: '10px' }} scope="col">
                    PP
                  </th>
                  <th style={{ paddingLeft: '10px' }} scope="col">
                    S1
                  </th>
                  <th style={{ paddingLeft: '10px' }} scope="col">
                    S2
                  </th>
                  <th style={{ paddingLeft: '10px' }} scope="col">
                    S3
                  </th>
                </tr>
              </thead>
              <tbody>
                {classic.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">Classic</th>
                    <td>{formatNum(item.R1)}</td>
                    <td>{formatNum(item.R2)}</td>
                    <td>{formatNum(item.R3)}</td>
                    <td>{formatNum(item.C)}</td>
                    <td>{formatNum(item.S1)}</td>
                    <td>{formatNum(item.S2)}</td>
                    <td>{formatNum(item.S3)}</td>
                  </tr>
                ))}
                {Fibonacci.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">Fibonacci</th>
                    <td>{formatNum(item.R1)}</td>
                    <td>{formatNum(item.R2)}</td>
                    <td>{formatNum(item.R3)}</td>
                    <td>{formatNum(item.C)}</td>
                    <td>{formatNum(item.S1)}</td>
                    <td>{formatNum(item.S2)}</td>
                    <td>{formatNum(item.S3)}</td>
                  </tr>
                ))}
                {Camarilla.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">Camarilla</th>
                    <td>{formatNum(item.R1)}</td>
                    <td>{formatNum(item.R2)}</td>
                    <td>{formatNum(item.R3)}</td>
                    <td>{formatNum(item.C)}</td>
                    <td>{formatNum(item.S1)}</td>
                    <td>{formatNum(item.S2)}</td>
                    <td>{formatNum(item.S3)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default Weekly;
